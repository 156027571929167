import { useAuthStore } from '~~/store/auth';

function _getAuthHeaders() {
  const store = useAuthStore();
  return {
    Authorization: `Bearer ${store.user.api_token}`
  }
}

function _getApiUrl() {
  const runtimeConfig = useRuntimeConfig();
  return runtimeConfig.public.api.endpoint;
}

function _transformUserFromServer(user) {
  user.role = Array.isArray(user.roles) && user.roles.length > 0 
    ? user.roles[0]
    : null;
  return user;
}

function _transformUserForServer(user) {
  // roles
  const data = {...user, roles: [user.role]};
  delete data.role;

  // events
  data.events = Array.isArray(data.events)
    ? data.events.map(event => event.id)
    : [];

  return data;
}


export async function getUsers() {
  const { data } = await useFetch(() => `${_getApiUrl()}/users`, { 
    headers: _getAuthHeaders()
  });
  if (data.value) {
    data.value = data.value.map(item => _transformUserFromServer(item));
  }
  return data;
}

export async function getUser(id) {
  const { data } = await useFetch(() => `${_getApiUrl()}/users/${id}`, { 
    headers: _getAuthHeaders()
  });
  if (data.value) {
    data.value = _transformUserFromServer(data.value);
  }
  return data;
}

export async function getCurrentUser() {
  const { data } = await useFetch(() => `${_getApiUrl()}/users/me`, { 
    headers: _getAuthHeaders()
  });
  if (data.value) {
    data.value = _transformUserFromServer(data.value);
  }
  return data;
}

export async function createUser(user) {
  const { data } = await useFetch(() => `${_getApiUrl()}/users`, { 
    method: 'POST',
    headers: _getAuthHeaders(),
    body: _transformUserForServer(user)
  });
  return data;
}

export async function updateUser(user) {
  const { data } = await useFetch(() => `${_getApiUrl()}/users/${user.id}`, { 
    method: 'PUT',
    headers: _getAuthHeaders(),
    body: _transformUserForServer(user)
  });
  return data;
}

export async function updateCurrentUser(user) {
  const { data } = await useFetch(() => `${_getApiUrl()}/users/me`, { 
    method: 'PUT',
    headers: _getAuthHeaders(),
    body: _transformUserForServer(user)
  });
  return data;
}

export async function getUserRoles() {
  const { data } = await useFetch(() => `${_getApiUrl()}/userroles`, { 
    headers: _getAuthHeaders()
  });
  return data;
}

export async function deleteUser(id) {
  const { data } = await useFetch(() => `${_getApiUrl()}/users/${id}`, { 
    method: 'DELETE',
    headers: _getAuthHeaders(),
  });
  return data;
}
